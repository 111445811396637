// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, {
    useState, useRef, useEffect, RefObject,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Col, Row } from 'antd/lib/grid';
import Text from 'antd/lib/typography/Text';
import Form, { FormInstance } from 'antd/lib/form';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import notification from 'antd/lib/notification';

import patterns from 'utils/validation-patterns';
import { CombinedState } from 'reducers/interfaces';
import LabelsEditor from 'components/labels-editor/labels-editor';
import { createProjectAsync } from 'actions/projects-actions';
import RewardPerObjectFormItem from './create-project-reward-per-object';
import Select from 'antd/lib/select';

const { TextArea } = Input;

function NameConfigurationForm({ formRef }: { formRef: RefObject<FormInstance> }): JSX.Element {
    return (
        <Form layout='vertical' ref={formRef}>
            <Form.Item
                name='name'
                hasFeedback
                label='Name'
                rules={[
                    {
                        required: true,
                        message: 'Please, specify a name',
                    },
                ]}
            >
                <Input />
            </Form.Item>
        </Form>
    );
}

function AdvanvedConfigurationForm({ formRef }: { formRef: RefObject<FormInstance> }): JSX.Element {
    return (
        <Form layout='vertical' ref={formRef}>
            <Form.Item
                name='guideline_link'
                label='Instruction link'
                extra='Attach Instruction link where the project is described'
                hasFeedback
                rules={[
                    {
                        validator: (_, value, callback): void => {
                            if (value && !patterns.validateURL.pattern.test(value)) {
                                callback('Instruction link must be URL');
                            } else {
                                callback();
                            }
                        },
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name='annotation_type'
                label='Annotation type'
                hasFeedback
                rules={[
                    {
                        required: true,
                        message: 'This field is required',
                    },
                ]}
            >
                <Select
                    placeholder="Annotation type"
                    options={[
                        {
                            value: 'object_detection',
                            label: 'Object Detection',
                        },
                        {
                            value: 'object_segmentation',
                            label: 'Object Segmentation',
                        },
                        {
                            value: 'object_tracking',
                            label: 'Object Tracking',
                        },
                        {
                            value: 'ocr',
                            label: 'OCR',
                        },
                        {
                            value: 'other',
                            label: 'Other',
                        },
                    ]}
                />

            </Form.Item>
            <Form.Item
                name='summary'
                label='Summary'
                hasFeedback
                rules={[
                    {
                        required: true,
                        message: 'This field is required',
                    },
                ]}
            >
                <TextArea showCount rows={4} placeholder="maxLength is 200" maxLength={200} />
            </Form.Item>
            <CreateProjectRewardPerObjectContent />
        </Form>
    );
}

export default function CreateProjectContent(): JSX.Element {
    const [projectLabels, setProjectLabels] = useState<any[]>([]);
    const shouldShowNotification = useRef(false);
    const nameFormRef = useRef<FormInstance>(null);
    const advancedFormRef = useRef<FormInstance>(null);
    const dispatch = useDispatch();
    const history = useHistory();

    const newProjectId = useSelector((state: CombinedState) => state.projects.activities.creates.id);

    useEffect(() => {
        if (Number.isInteger(newProjectId) && shouldShowNotification.current) {
            const btn = <Button onClick={() => history.push(`/projects/${newProjectId}`)}>Open project</Button>;

            // Clear new project forms
            if (nameFormRef.current) nameFormRef.current.resetFields();
            if (advancedFormRef.current) advancedFormRef.current.resetFields();
            setProjectLabels([]);

            notification.info({
                message: 'The project has been created',
                btn,
            });
        }

        shouldShowNotification.current = true;
    }, [newProjectId]);

    const onSumbit = async (): Promise<void> => {
        interface Project {
            [key: string]: any;
        }

        const projectData: Project = {};
        let reward_per_object: any = {
            annotator: {},
            reviewer: {}
        };
        if (nameFormRef.current && advancedFormRef.current) {
            const basicValues = await nameFormRef.current.validateFields();
            const advancedValues = await advancedFormRef.current.validateFields();
            projectData.name = basicValues.name;
            for (const [field, value] of Object.entries(advancedValues)) {
                if (field.includes('shape') || field.includes('track') || field.includes('issue')) {

                    let splitted_key: string[] = field.split('_');
                    
                    if (splitted_key.includes('issue')) {
                        reward_per_object = {
                            ...reward_per_object,
                            [splitted_key[0]]: {
                                ...reward_per_object[splitted_key[0]],
                                'issue': value
                            }
                        }

                    } else {
                        reward_per_object = {
                            ...reward_per_object,
                            [splitted_key[0]]: {
                                ...reward_per_object[splitted_key[0]],
                                [splitted_key[1] + '_' + splitted_key[2]]: value
                            }
                        }
                    }


                    projectData['reward_per_object'] = reward_per_object;
                } else {
                    projectData[field] = value;
                }
            }
        }

        projectData.labels = projectLabels;
        if (!projectData.name) return;

        dispatch(createProjectAsync(projectData));
    };

    return (
        <Row justify='start' align='middle' className='cvat-create-project-content'>
            <Col span={24}>
                <NameConfigurationForm formRef={nameFormRef} />
            </Col>
            <Col span={24}>
                <Text className='cvat-text-color'>Labels:</Text>
                <LabelsEditor
                    labels={projectLabels}
                    onSubmit={(newLabels): void => {
                        setProjectLabels(newLabels);
                    }}
                />
            </Col>
            <Col span={24}>
                <AdvanvedConfigurationForm formRef={advancedFormRef} />
            </Col>
            <Col span={24}>
                <Button type='primary' onClick={onSumbit}>
                    Submit
                </Button>
            </Col>
        </Row>
    );
}


const CreateProjectRewardPerObjectContent = () => {

    const DATA = ['rectangle', 'polygon', 'polyline', 'points', 'cuboid', 'issue']


    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '49%' }}>
                <Text type="secondary">Annotator</Text>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {
                        DATA.map(element => {
                            if (element === 'issue') {
                                return <div key={element} style={{ display: 'flex' }}>
                                    <RewardPerObjectFormItem element={element} role='annotator' type="issue" />
                                </div>
                            } else {
                                return <div key={element} style={{ display: 'flex' }}>
                                    <RewardPerObjectFormItem element={element} role='annotator' type="shape" />
                                    <RewardPerObjectFormItem element={element} role='annotator' type="track" />
                                </div>
                            }

                        })
                    }
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '49%' }}>
                <Text type="secondary">Reviewer</Text>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {
                        DATA.map(element => {
                            if (element === 'issue') {
                                return <div key={element} style={{ display: 'flex' }}>
                                    <RewardPerObjectFormItem element={element} role='reviewer' type="issue" />
                                </div>
                            } else {
                                return <div key={element} style={{ display: 'flex' }}>
                                    <RewardPerObjectFormItem element={element} role='reviewer' type="shape" />
                                    <RewardPerObjectFormItem element={element} role='reviewer' type="track" />
                                </div>
                            }

                        })
                    }
                </div>
            </div>
        </div>
    )
}