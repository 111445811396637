// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useState } from 'react';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import Text from 'antd/lib/typography/Text';
import { Row, Col } from 'antd/lib/grid';

import patterns from 'utils/validation-patterns';

interface Props {
    instance: any;
    onChange: (guideline_link: string) => void;
}

export default function BugTrackerEditorComponent(props: Props): JSX.Element {
    const { instance, onChange } = props;

    const [guidelineLink, setGuidelineLink] = useState(instance.guideline_link);
    const [guidelineLinkEditing, setGuidelineLinkEditing] = useState(false);

    const instanceType = Array.isArray(instance.tasks) ? 'project' : 'task';
    let shown = false;

    const onStart = (): void => setGuidelineLinkEditing(true);
    const onChangeValue = (value: string): void => {
        if (value && !patterns.validateURL.pattern.test(value)) {
            if (!shown) {
                Modal.error({
                    title: `Could not update the ${instanceType} ${instance.id}`,
                    content: `${instanceType === 'project' ? 'Instruction' : 'Issue Tracker'} is expected to be URL`,
                    onOk: () => shown = false,
                });
                shown = true;
            }
        } else {
            setGuidelineLink(value);
            setGuidelineLinkEditing(false);
            onChange(value);
        }
    };

    let text = <Text strong className='cvat-text-color'>{instanceType === 'project' ? 'Instruction' : 'Issue Tracker'}</Text>

    if (guidelineLink) {
        return (
            <Row className='cvat-issue-tracker'>
                <Col>
                    {text}
                    <br />
                    <Text editable={{ onChange: onChangeValue }}>{guidelineLink}</Text>
                </Col>
            </Row>
        );
    }

    return (
        <Row className='cvat-issue-tracker'>
            <Col>
                {text}
                <br />
                <Text
                    editable={{
                        editing: guidelineLinkEditing,
                        onStart,
                        onChange: onChangeValue,
                    }}
                >
                    {guidelineLinkEditing ? '' : 'Not specified'}
                </Text>
            </Col>
        </Row>
    );
}
