// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT
//

import React from 'react';
import { useHistory } from 'react-router';
import { Row, Col } from 'antd/lib/grid';
import Icon, { LeftOutlined } from '@ant-design/icons';
import Button from 'antd/lib/button';
import Dropdown from 'antd/lib/dropdown';
import Text from 'antd/lib/typography/Text';

import ActionsMenuContainer from 'containers/actions-menu/actions-menu';
import { MenuIcon } from 'icons';
import { useSelector } from 'react-redux';
import { CombinedState, ROLES } from 'reducers/interfaces';
interface DetailsComponentProps {
    taskInstance: any;
    isAdmin: boolean;
}

export default function DetailsComponent(props: DetailsComponentProps): JSX.Element {
    const { taskInstance, isAdmin } = props;
    const user = useSelector((state: CombinedState) => state.auth.user);
    const history = useHistory();


    return (
        <Row className='cvat-task-top-bar' justify='space-between' align='middle'>
            <Col>
                {
                    (taskInstance.projectId && !user.groups.includes(ROLES.ANNOTATOR)) && <Button
                        onClick={() => history.push(`/projects/${taskInstance.projectId}`)}
                        type='link'
                        size='large'
                    >
                        <LeftOutlined />
                        Back to project
                    </Button>
                }

            </Col>
            <Col>
                {isAdmin && (
                    <Dropdown overlay={<ActionsMenuContainer taskInstance={taskInstance}/>}>
                        <Button size='large'>
                            <Text className='cvat-text-color'>Actions</Text>
                            <Icon className='cvat-menu-icon' component={MenuIcon} />
                        </Button>
                    </Dropdown>
                )}
                {
                    user.groups.includes(ROLES.CLIENT) && (
                        <>
                            <Dropdown overlay={<ActionsMenuContainer client={true} taskInstance={taskInstance} />}>
                                <Button size='large'>
                                    <Text className='cvat-text-color'>Actions</Text>
                                    <Icon className='cvat-menu-icon' component={MenuIcon} />
                                </Button>
                            </Dropdown>
                        </>
                    )
                }
            </Col>
        </Row>
    );
}
