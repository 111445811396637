
import { Button, notification } from 'antd';
import { onboardingActions } from 'actions/onboarding-actions';
import { onboardingActionDraw } from './onboarding-confiig';
import { useSelector, useDispatch } from 'react-redux';
import { CombinedState } from 'reducers/interfaces';
import React, { useEffect } from 'react';



const CheckOnboarding = () => {
    const annotations = useSelector((state: CombinedState) => state.annotation);
    const { enabled, stepIndex } = useSelector((state: CombinedState) => state.onboarding);
    const dispatch = useDispatch();

    function removeIconMenuFromDOM() {
        let headersBtn: any = document.querySelectorAll('.cvat-annotation-header-button')
        if (headersBtn) {
            headersBtn[0].style.display = 'none'
        }
    }

    useEffect(() => {
        if (enabled === true) {
            let activeControl = onboardingActionDraw[stepIndex]
            removeIconMenuFromDOM()

            document.querySelectorAll('.anticon').forEach((el: any) => {
                if (el.classList.contains(activeControl.step)) {
                    return
                }
                el.style.pointerEvents = 'none'
                el.style.opacity = 0.2
            })
        }

        return () => {
            let headersBtn: any = document.querySelectorAll('.cvat-annotation-header-button')
            if (headersBtn) {
                headersBtn[0].style.display = 'block'
            }
            document.querySelectorAll('.anticon').forEach((el: any) => {
                el.style.pointerEvents = 'auto'
                el.style.opacity = 1
            })
        }

    }, [enabled])





    const onFinish = (): void => {
        let count: number = annotations.annotations.states.length;
        let activeControl: any = onboardingActionDraw[stepIndex];

        if (count < activeControl.length) {
            notification.error({
                duration: 7,
                message: 'Notification Title',
                description: `Need at least ${activeControl.length} elements`
            });
        } else {
            notification.success({
                duration: 4,
                message: 'Notification Success Title',
                description: 'Good job'
            });
            dispatch(onboardingActions.setRun(true))
            dispatch(onboardingActions.setFinishOnboarding(true))
            dispatch(onboardingActions.setEnabled(false))
            dispatch(onboardingActions.setCurrentIndexIncrement())
        }
    }

    return (
        <>
            {(enabled === true) && <div className="finish-onboarding">
                <p>Press finish button to complete this  onboarding step.</p>
                <Button onClick={onFinish} type="primary" size="large">Finish</Button>
            </div>}
        </>
    )
}

export default CheckOnboarding